@font-face {
    font-family: "icomoon";
    src: url("./assets/fonts/icomoon.eot?fuasyh");
    src: url("./assets/fonts/icomoon.eot?fuasyh#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/icomoon.ttf?fuasyh") format("truetype"),
        url("./assets/fonts/icomoon.woff?fuasyh") format("woff"),
        url("./assets/fonts/icomoon.svg?fuasyh#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-dashboard::before {
    content: "\e914";
}
.icon-manage-profile::before {
    content: "\e930";
}
.icon-staff::before {
    content: "\e92b";
}
.icon-branches::before {
    content: "\e94f";
}
.icon-purchase-history::before {
    content: "\e912";
}
.icon-purchase-request::before {
    content: "\e931";
}
.icon-chat::before {
    content: "\e951";
}
.icon-notification::before {
    content: "\e925";
}
.icon-support-ticket::before {
    content: "\e932";
}
.icon-logout::before {
    content: "\e91b";
}
.icon-offers::before {
    content: "\e927";
}
.icon-product-detail::before {
    content: "\e92f";
}
.icon-send-purachase::before {
    content: "\e937";
}
.icon-orders::before {
    content: "\e929";
}
.icon-bill::before {
    content: "\e933";
}
.icon-down-arrow::before {
    content: "\e905";
}
.icon-verticle-dots::before {
    content: "\e941";
}
.icon-circle-plus::before {
    content: "\e902";
}
.icon-visa::before {
    content: "\e942";
}
.icon-plus::before {
    content: "\e92d";
}
.icon-left_arrow::before {
    content: "\e919";
}
.icon-right_arrow::before {
    content: "\e935";
}
.icon-headphone::before {
    content: "\e926";
}

.icon-card::before {
    content: "\e954";
}
.icon-verify::before {
    content: "\e953";
}

.icon-path01::before {
    content: "\e955";
}

.icon-fruits::before {
    content: "\e90f";
}

.icon-vegetables::before {
    content: "\e940";
}

.icon-herbs::before {
    content: "\e911";
}

.icon-oragnic::before {
    content: "\e928";
}

.icon-berries::before {
    content: "\e957";
}
.icon-frozen::before {
    content: "\e956";
}
.icon-site-logo::before {
    content: "\e97e";
}
.icon-star-fill::before {
    content: "\e938";
}
.icon-star-outline::before {
    content: "\e939";
}
.icon-quotes::before {
    content: "\e959";
}
.icon-drink::before {
    content: "\e90e";
}

.icon-herbal-tea::before {
    content: "\e910";
}

.icon-check::before {
    content: "\e952";
}

.icon-linkdin::before {
    content: "\e91a";
}

.icon-twitter::before {
    content: "\e93e";
}
.icon-dribbble-alt::before {
    content: "\e90d";
}

.icon-navigation::before {
    content: "\e924";
}

.icon-mail::before {
    content: "\e91c";
}
.icon-call::before {
    content: "\e950";
}

.icon-emails-alt::before {
    content: "\e95c";
}

.icon-password::before {
    content: "\e95b";
}

.icon-eye::before {
    content: "\e95d";
}
.icon-check-alt-2::before {
    content: "\e900";
}
.icon-facebook::before {
    content: "\e95e";
}
.icon-instagram-outline::before {
    content: "\e918";
}
.icon-twitter::before {
    content: "\e93e";
}
.icon-linkdin::before {
    content: "\e91a";
}
.icon-arrow-swap::after {
    content: "\e95f";
}
.icon-filter::before {
    content: "\e960";
}
.icon-search-icon::before {
    content: "\e961";
}
.icon-dashboard::before {
    content: "\e963";
}
.icon-store::before {
    content: "\e964";
}
.icon-cms-page::before {
    content: "\e965";
}
.icon-supplier::before {
    content: "\e962";
}
.icon-slug::before {
    content: "\e966";
}
.icon-trash-alt::before {
    content: "\e967";
}
.icon-edit-alt::before {
    content: "\e968";
}
.icon-pay::before {
    content: "\e92a";
}
.icon-product::before {
    content: "\e92e";
}
.icon-truck::before {
    content: "\e93d";
}
.icon-booking::before {
    content: "\e94e";
}
.icon-measurement::before {
    content: "\e969";
}

.icon-category::before {
    content: "\e96a";
}
.icon-pages-alt::before {
    content: "\e96b";
}

.icon-product-alt::before {
    content: "\e96c";
}

.icon-cross::before {
    content: "\e903";
}

.icon-trashed-alt::before {
    content: "\e96d";
}
.icon-shopping-bage::before {
    content: "\e96e";
}

.icon-send-message::before {
    content: "\e96f";
}
.icon-attachment::before {
    content: "\e970";
}

.icon-download::before {
    content: "\e906";
}
.icon-close::before {
    content: "\e903";
}
.icon-refersh::before {
    content: "\e971";
}

.icon-orders-alt::before {
    content: "\e976";
}
.icon-get-in-touch::before {
    content: "\e975";
}
.icon-feedback::before {
    content: "\e974";
}
.icon-rolles-alts::before {
    content: "\e973";
}
.icon-products-alt::before {
    content: "\e972";
}
.icon-feedback::before {
    content: "\e974";
}
.icon-payment::before {
    content: "\e977";
}
.icon-bank_transfer::before {
    content: "\e978";
}
.icon-payment-pending::before {
    content: "\e979";
}
.icon-dollar::before {
    content: "\e904";
}
.icon-purches-request::before {
    content: "\e933";
}
.icon-settings::before {
    content:"\e97a"
}