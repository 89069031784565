body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hover-effects {
  @apply relative before:hover:content-[""] after:hover:content-[""] before:hover:shadow-menu-befores after:hover:shadow-menu-after before:hover:rounded-r-80px after:hover:rounded-r-80px before:hover:absolute after:hover:absolute after:hover:top-52px after:hover:-right-0 rtl:after:hover:left-0 rtl:after:hover:right-unset rtl:before:hover:left-0 rtl:before:hover:right-unset before:hover:-right-0 after:hover:h-20 before:hover:h-20 before:hover:w-4 after:hover:w-4  before:hover:-top-80px rtl:before:hover:rounded-l-80px rtl:before:hover:rounded-r-none rtl:after:hover:rounded-l-80px rtl:after:hover:rounded-r-none;
}

.active-hover-effects {
  @apply relative before:content-[""] after:content-[""] before:shadow-menu-befores after:shadow-menu-after before:rounded-r-80px after:rounded-r-80px before:absolute after:absolute after:top-52px after:-right-0 before:-right-0 rtl:before:left-0 rtl:before:right-unset rtl:before:rounded-l-80px rtl:before:rounded-r-none rtl:after:rounded-l-80px rtl:after:rounded-r-none rtl:after:left-0 rtl:after:right-unset  after:h-20 before:h-20 before:w-4 after:w-4  before:-top-80px;
}
.custom-select-box .css-13cymwt-control, .custom-select-box .css-t3ipsp-control, .custom-select-box .css-16xfy0z-control{
    @apply !border-black/10 !h-12 rounded-xl px-4; 
}
.css-1fdsijx-ValueContainer{
    @apply !p-0
 }
 .custom-select-box .css-1u9des2-indicatorSeparator, .custom-select-box .css-894a34-indicatorSeparator{
    @apply hidden;
 }
 .custom-select-box .css-1xc3v61-indicatorContainer{
    @apply p-0;
 }

.global-loader span  div{
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-primarycolor/80 w-full h-full flex justify-center;
}
.global-loader span  div svg{
  @apply !m-0;
}